.player_wrapper {
  height: 70px;
  width: 90%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  color: rgb(0, 0, 0);
  z-index: 100;
  border-top: 1px solid rgb(216, 216, 216);
  backdrop-filter: blur(10px);
  background: rgb(255, 255, 255);

  .description_music_wrapper {
    width: 400px;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .cover_wrapper {
      div {
        height: 35px;
        width: 35px;
        background: #333;
        margin-right: 10px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 35px;
          width: 35px;
          border-radius: 50px;
        }
      }
    }
    .description {
      div {
        &:nth-child(1) {
          font-weight: bold;
          font-size: 16px;
          text-transform: uppercase;
        }
        &:nth-child(2) {
          font-size: 11px;
          color: #777;
        }
      }
    }
  }
  .controls_wrapper {
    // width: 300px;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    .more {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      border: none;
      // background: #222;
      height: 25px;
      width: 25px;
      border-radius: 50px;
      border: 1px solid #b1b1b19c;
      cursor: pointer;
    }
    .time {
      font-size: 11px;
      margin: 0 10px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .buttonPlayer {
      height: 30px;
      width: 30px;
      border-radius: 50px;
      margin: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .play {
      height: 40px;
      width: 40px;
      border-radius: 40px;
      margin: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: 1px solid #b1b1b19c;
      i {
        position: relative;
        // left: 2px;
        font-size: 12px;
      }
    }
  }
  .seekBar_wrapper {
    width: 60%;
    height: inherit;

    display: flex;
    align-items: center;
    justify-content: center;

    .seekbar {
      width: 80%;
      background: #333;
      height: 5px;
      margin: 0 20px;
    }
  }
  .platform_container {
    width: 400px;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    margin-left: 20px;
    border-radius: 0 10px 10px 0;
    .nav_item {
      margin: 20px;
      text-transform: uppercase;
      font-size: 12px;
      cursor: pointer;
      transition: all ease-in-out 0.3s;
      a {
        color: #000;
        text-decoration: none;
        transition: all ease-in-out 0.3s;

        &:hover {
          border-left: 8px solid #fff;
          color: #ff9900;
        }
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 992px) {
  .player_wrapper {
    .description_music_wrapper {
      display: none;
    }
    .controls_wrapper {
      width: 100%;
    }
    .seekBar_wrapper {
      display: none;
    }
    .platform_container {
      display: none;
    }
  }
}
