.resume_wrapper {
  min-height: 60vh;
  height: auto;
  width: 100%;
  background: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 50px 0;
}

.resume_wrapper .resume_container {
  height: inherit;
  width: 95%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.resume_wrapper .resume_container .left {
  width: 50%;
  height: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.resume_wrapper .resume_container .left .text_container {
  width: 60%;
}

.resume_wrapper .resume_container .left .text_container .category {
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 5px;
  font-family: "Rogan-light";
  border-bottom: 1px solid #fff;
  padding: 5px 0;
  width: 100px;
}

.resume_wrapper .resume_container .left .text_container .text {
  font-family: "Rogan-light";
  font-size: 20px;
}

.resume_wrapper .resume_container .right {
  width: 50%;
  height: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.resume_wrapper .resume_container .right img {
  width: 70%;
  height: 600px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top;
}

@media (min-width: 0px) and (max-width: 992px) {
  .resume_wrapper {
    min-height: 60vh;
    height: auto;
    width: 100%;
    background: #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .resume_wrapper .resume_container {
    height: inherit;
    width: 95%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .resume_wrapper .resume_container .left {
    width: 90%;
    height: inherit;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .resume_wrapper .resume_container .left .text_container {
    width: 90%;
    margin-top: 20px;
  }
  .resume_wrapper .resume_container .left .text_container .category {
    text-transform: uppercase;
    margin-bottom: 20px;
    letter-spacing: 5px;
    font-family: "Rogan-light";
    border-bottom: 1px solid #fff;
    padding: 5px 0;
    width: 100px;
  }
  .resume_wrapper .resume_container .left .text_container .text {
    font-family: "Rogan-light";
  }
  .resume_wrapper .resume_container .right {
    width: 90%;
    height: inherit;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .resume_wrapper .resume_container .right img {
    width: 95%;
    height: 500px;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: top;
       object-position: top;
  }
}
