.menu_full {
  height: 100vh;
  width: 100%;
  background: #000;
  position: fixed;
  z-index: 9999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  right: 0;
  display: none;
}

.menu_full .nav_wrapper {
  width: 200px;
}

.menu_full .nav_wrapper a {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
}

.menu_full .nav_wrapper a .nav_item {
  font-size: 2.5rem;
  -webkit-transition: all ease .3s;
  transition: all ease .3s;
}

.menu_full .nav_wrapper a .nav_item:hover {
  border-left: 8px solid #fff;
  color: #ff9900;
}

.menu_full .social_full {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 20px;
  width: 200px;
}

.menu_full .social_full .category {
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 5px;
  font-family: "Rogan-light";
  border-bottom: 1px solid #fff;
  padding: 5px 0;
  width: 205px;
}

.menu_full .social_full .social_full_center_icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.menu_full .social_full .social_full_center_icons .nav_item_social {
  width: 40px;
  height: 40px;
  border: 1px solid #ffffff;
  margin: 0 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}

.menu_full .social_full .social_full_center_icons .nav_item_social i {
  color: #fff;
}

.menu_full .social_full .social_full_center_icons .nav_item_social:hover {
  border: 1px solid #ff9900;
  color: #ff9900;
}

.menu_full .social_full .social_full_center_icons .nav_item_social:hover i {
  color: #ff9900;
}

.menu_full .close {
  color: #fff;
  font-size: 30px;
  position: absolute;
  right: 10%;
  top: 30px;
  font-family: "Rogan-light";
  cursor: pointer;
}

.menu_wrapper {
  height: 100px;
  width: 90%;
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  z-index: 900;
  padding: 0 5%;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.menu_wrapper .nav_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.menu_wrapper .nav_wrapper .nav_item {
  text-transform: uppercase;
  font-size: 12px;
  padding: 0 20px;
  -webkit-transition: all ease-in-out 0.6s;
  transition: all ease-in-out 0.6s;
  cursor: pointer;
}

.menu_wrapper .nav_wrapper .nav_item:hover {
  border-left: 8px solid #fff;
  color: #ff9900;
}

.menu_wrapper .nav_wrapper a {
  text-decoration: none;
}

.menu_wrapper .nav_wrapper a .nav_item {
  color: #fff;
}

.menu_wrapper .nav_wrapper .nav_item_social {
  text-transform: uppercase;
  font-size: 12px;
  padding: 0 5px;
  -webkit-transition: all ease-in-out 0.6s;
  transition: all ease-in-out 0.6s;
  cursor: pointer;
}

.menu_wrapper .nav_wrapper .nav_item_social a {
  color: #fff;
}

.menu_wrapper .nav_wrapper .nav_item_social a:first-child {
  padding-left: 20px;
}

.menu_wrapper .nav_wrapper .nav_item_social a i {
  width: 35px;
  height: 35px;
  border: 1px solid #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.menu_wrapper .nav_wrapper .nav_item_social a i:hover {
  border: 1px solid #ff9900;
  color: #ff9900;
}

.menu_wrapper .nav_wrapper .nav_item_social a i:hover i {
  color: #ff9900;
}

.menu_wrapper .nav_wrapper .divisa {
  height: 20px;
  width: 1px;
  background: #7a7a7a;
  margin-right: 15px;
}

.menu_wrapper .logo_wrapper {
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.menu_wrapper .logo_wrapper img {
  width: 50px;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.menu_wrapper .drag_container {
  height: 20px;
  width: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  display: none;
}

.menu_wrapper .drag_container div {
  height: 2px;
  width: 50%;
  background: #fff;
}

.active {
  background: #000;
  height: 70px;
}

.active .logo_wrapper {
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.active .logo_wrapper img {
  width: 40px;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

@media (min-width: 0px) and (max-width: 992px) {
  .menu_wrapper {
    height: 100px;
    width: 90%;
    position: fixed;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    z-index: 900;
    padding: 0 5%;
    -webkit-transition: all ease 0.3s;
    transition: all ease 0.3s;
  }
  .menu_wrapper .nav_wrapper {
    display: none;
  }
  .menu_wrapper .logo_wrapper {
    text-transform: uppercase;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;
  }
  .menu_wrapper .logo_wrapper img {
    width: 50px;
    -webkit-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;
  }
  .menu_wrapper .drag_container {
    height: 20px;
    width: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .menu_wrapper .drag_container div {
    height: 2px;
    width: 50%;
    background: #fff;
  }
  .active {
    background: #000;
    height: 70px;
  }
  .active .logo_wrapper {
    text-transform: uppercase;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;
  }
  .active .logo_wrapper img {
    width: 40px;
    -webkit-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;
  }
}
