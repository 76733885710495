.footer_wrapper {
  width: 100%;
  height: 350px;
  background: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
      border-top: 1px solid #111;
  .footer_container {
    width: 85%;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .box_footer {
      height: 140px;
      width: 380px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      text-transform: uppercase;
      span {
        color: #ff9900;
        font-size: 12px;
      }
      .info_text {
        color: #ff9900;
      }
      .text_print {
        font-family: "Rogan-light";
        margin-bottom: 10px;
        span {
          color: #ff9900;
          font-family: "Rogan";
          font-size: 16px;
        }
      }
      .title_category_footer {
        text-transform: uppercase;
        margin-bottom: 20px;
        letter-spacing: 5px;
        font-family: "Rogan-light";
        border-bottom: 1px solid #fff;
        padding: 5px 0;
      }
      img {
        width: 80px;
      }
      a {
        text-decoration: none;
        button {
          height: 50px;
          background-color: #ff9900;
          width: 380px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all ease-in-out 0.4s;
          border: none;
          text-transform: uppercase;
          font-weight: bold;
          font-family: "Rogan";
          cursor: pointer;
          &:hover {
            background-color: #c97800;
          }
        }
      }
    }
  }
  .sunfooter {
    height: 100px;
    width: 85%;
    border-top: 1px solid #111;
    font-family: "Rogan-light";
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color: #888;
    padding: 0 7.5%;
    .king_logo_wrapper {
      img {
        width: 50px;
      }
    }

    .social_footer {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .nav_item_social {
        width: 40px;
        height: 40px;
        border: 1px solid #ffffff;
        margin: 0 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        i {
          color: #fff;
        }
        &:hover {
          border: 1px solid #ff9900;
          color: #ff9900;
          i {
            color: #ff9900;
          }
        }
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 992px) {
  .footer_wrapper {
    width: 100%;
    min-height: 250px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .footer_container {
      width: 85%;
      min-height: 250px;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 50px 0;
      .box_footer {
        min-height: 140px;
        height: auto;
        width: 90%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        text-transform: uppercase;
        border-bottom: 1px solid #222;
        padding: 20px 0 0 0;
        &:last-child{
          border-bottom: 1px solid rgba(34, 34, 34, 0);
        }
        span {
          color: #ff9900;
          font-size: 12px;
        }
        .info_text {
          color: #ff9900;
        }
        .text_print {
          font-family: "Rogan-light";
          margin-bottom: 10px;
          span {
            color: #ff9900;
            font-family: "Rogan";
            font-size: 16px;
          }
        }
        .title_category_footer {
          text-transform: uppercase;
          margin-bottom: 20px;
          letter-spacing: 5px;
          font-family: "Rogan-light";
          border-bottom: 1px solid #fff;
          padding: 5px 0;
        }
        img {
          width: 80px;
        }
        a {
          text-decoration: none;
          button {
            height: 50px;
            background-color: #ff9900;
            width: 250px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all ease-in-out 0.4s;
            border: none;
            text-transform: uppercase;
            font-weight: bold;
            font-family: "Rogan";
            cursor: pointer;
            &:hover {
              background-color: #c97800;
            }
          }
        }
      }
    }
    .sunfooter {
      height: 50px;
      // width: 100%;

      font-family: "Rogan-light";
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 10px;
      color: #888;
      .king_logo_wrapper {
        img {
          width: 50px;
        }
      }
  
      .social_footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .nav_item_social {
          width: 25px;
          height: 25px;
          border: 1px solid #ffffff;
          margin: 0 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          display: none;
          i {
            color: #fff;
          }
          &:hover {
            border: 1px solid #ff9900;
            color: #ff9900;
            i {
              color: #ff9900;
            }
          }
        }
      }
    }
  }
}
