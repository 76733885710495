.video_wrapper {
  min-height: 100vh;
  height: auto;
  width: 100%;
  background: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-bottom: 1px solid #111;
}

.video_wrapper .video_container {
  min-height: 100vh;
  height: auto;
  width: 95%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.video_wrapper .video_container .category {
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 5px;
  font-family: "Rogan-light";
  border-bottom: 1px solid #fff;
  padding: 5px 0;
  margin: 50px 0;
}

.video_wrapper .video_container iframe {
  width: 1080px;
  height: 600px;
}

.video_wrapper .category_streamings {
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 5px;
  font-family: "Rogan-light";
  border-bottom: 1px solid #fff;
  padding: 5px 0;
  margin: 50px 0 0px 0;
}

.video_wrapper .social_container {
  height: 150px;
  margin: 0 0 50px 0;
  width: 105%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.video_wrapper .social_container .social_middle {
  width: 1080px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.video_wrapper .social_container .social_middle img {
  width: 130px;
}

.video_wrapper .social_container img {
  width: 130px;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
  cursor: pointer;
}

.video_wrapper .social_container img:hover {
  opacity: 0.7;
}

@media (min-width: 0px) and (max-width: 992px) {
  .video_wrapper {
    min-height: 100vh;
    height: auto;
    width: 100%;
    background: #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .video_wrapper .video_container {
    min-height: 100vh;
    height: auto;
    width: 95%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .video_wrapper .video_container .category {
    text-transform: uppercase;
    margin-bottom: 20px;
    letter-spacing: 5px;
    font-family: "Rogan-light";
    border-bottom: 1px solid #fff;
    padding: 5px 0;
    margin: 50px 0;
  }
  .video_wrapper .video_container iframe {
    width: 90%;
    height: 200px;
  }
  .video_wrapper .social_container {
    min-height: 150px;
    height: auto;
    margin: 50px 0;
    width: 105%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background: #080808;
  }
  .video_wrapper .social_container .social_middle {
    width: 80%;
    min-height: 150px;
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    border: 1px solid rgba(255, 255, 255, 0.11);
  }
  .video_wrapper .social_container .social_middle img {
    width: 130px;
    margin: 20px 0;
  }
  .video_wrapper .social_container img {
    width: 130px;
    -webkit-transition: all ease-in-out 0.4s;
    transition: all ease-in-out 0.4s;
    cursor: pointer;
  }
  .video_wrapper .social_container img:hover {
    opacity: 0.7;
  }
}
