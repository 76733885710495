.inicio_wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../../Assets/img/back.png) no-repeat;
  background-position: center;
  background-size: cover;

  .inicio_container {
    width: 1366px;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: row-reverse;

    .left {
      width: 50%;
      height: 70vh;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-direction: column;
      align-self: center;
      button {
        height: 50px;
        width: 300px;
        border: none;
        text-transform: uppercase;
        font-weight: bold;
        background: #fff;
        font-family: "Rogan_extra";
        font-size: 20px;
        transition: all ease-in-out 0.3s;
        cursor: pointer;
        &:hover {
          background: #e1ff19;
        }
      }

      .text_middle {
        display: none;
        .text {
          font-size: 5.5rem;
          line-height: 4.5rem;
          font-family: "ts";
          .isso {
            span {
              position: relative;
              left: -50px;
              top: -20px;
            }
          }
        }

        .sub {
          width: 80%;
          font-family: "Rogan-light";
          margin-top: 30px;
          display: none;
        }
      }

      img {
        width: 150px;
        display: none;
      }
    }
    .image {
      width: 50%;
      height: 90vh;
    }
  }
}

@media (min-width: 0px) and (max-width: 992px) {

  .inicio_wrapper {
  
    .inicio_container {
      width:100%!important;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row-reverse;
  
      .left {
        width: 100%;
        height: calc(100vh - 160px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        align-self: center;
        button {
          height: 50px;
          width: 300px;
          border: none;
          text-transform: uppercase;
          font-weight: bold;
          background: #fff;
          font-family: "Rogan_extra";
          font-size: 20px;
          transition: all ease-in-out 0.3s;
          cursor: pointer;
          &:hover {
            background: #e1ff19;
          }
        }
  
      }
      .image {
        width: 50%;
        height: 90vh;
        display: none;
      }
    }
  }

}
