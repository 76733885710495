.agenda_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  background-color: #000;
  min-height: 100vh;
  height: auto;
  width: 100%;
  z-index: 9999;
  position: absolute;
  .agenda_container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: row;
    min-height: 50vh;
    height: auto;
    width: 1366px;
    padding: 30px;
    .title {
      font-size: 3rem;
      height: 80px;
      border-bottom: 1px solid #fff;
      margin-bottom: 50px;
      text-transform: uppercase;
    }
    .container_datas {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-start;
      flex-direction: row;
      min-height: 50vh;
      height: auto;
      width: 100%;

      .data_box {
        min-height: 100px;
        height: auto;
        padding: 10px;
        width: 300px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;

        div {
          margin-bottom: 5px;
        }
        .day {
          font-size: 2.6rem;
          span {
            color: #ff9900;
            font-family: "Rogan-light";
            &::after{
                content: ('hs');
           
            }
          }
        }
        .nome_evento {
          font-size: 1.5rem;

          text-transform: uppercase;
        }
        .endereco {
          font-family: "Rogan-light";
        }
        .mapa {
          color: #ff9900;
          text-transform: uppercase;
        }
        .paricular {
          text-transform: uppercase;
          font-size: 13px;
        }
      }
    }
  }
}

@media (min-width: 0px) and(max-width:992px) {
  .agenda_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    background-color: #000;
    min-height: 100vh;
    height: auto;
    width: 100%;
    z-index: 9999;
    position: absolute;
    .agenda_container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-start;
      flex-direction: row;
      min-height: 50vh;
      height: auto;
      width: 100%;
      padding: 50px 0px;
      .title {
        font-size: 3rem;
        height: 80px;
        border-bottom: 1px solid #fff;
        margin-bottom: 50px;
        text-transform: uppercase;
        padding-left: 30px;
      }
      .container_datas {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: flex-start;
        flex-direction: row;
        min-height: 50vh;
        height: auto;
        width: 100%;

        .data_box {
          min-height: 100px;
          height: auto;
          padding: 30px;
          width: 300px;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          border-bottom: 1px solid #333333;

          div {
            margin-bottom: 5px;
          }
          .day {
            font-size: 3rem;

          }
          .nome_evento {
            font-size: 1.5rem;

            text-transform: uppercase;
          }
          .endereco {
            font-family: "Rogan-light";
          }
          .mapa {
            color: #ff9900;
            text-transform: uppercase;
          }
          .paricular {
            text-transform: uppercase;
            font-size: 13px;
          }
        }
      }
    }
  }
}
