.footer_wrapper {
  width: 100%;
  height: 350px;
  background: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-top: 1px solid #111;
}

.footer_wrapper .footer_container {
  width: 85%;
  height: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.footer_wrapper .footer_container .box_footer {
  height: 140px;
  width: 380px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-transform: uppercase;
}

.footer_wrapper .footer_container .box_footer span {
  color: #ff9900;
  font-size: 12px;
}

.footer_wrapper .footer_container .box_footer .info_text {
  color: #ff9900;
}

.footer_wrapper .footer_container .box_footer .text_print {
  font-family: "Rogan-light";
  margin-bottom: 10px;
}

.footer_wrapper .footer_container .box_footer .text_print span {
  color: #ff9900;
  font-family: "Rogan";
  font-size: 16px;
}

.footer_wrapper .footer_container .box_footer .title_category_footer {
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 5px;
  font-family: "Rogan-light";
  border-bottom: 1px solid #fff;
  padding: 5px 0;
}

.footer_wrapper .footer_container .box_footer img {
  width: 80px;
}

.footer_wrapper .footer_container .box_footer a {
  text-decoration: none;
}

.footer_wrapper .footer_container .box_footer a button {
  height: 50px;
  background-color: #ff9900;
  width: 380px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
  border: none;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Rogan";
  cursor: pointer;
}

.footer_wrapper .footer_container .box_footer a button:hover {
  background-color: #c97800;
}

.footer_wrapper .sunfooter {
  height: 100px;
  width: 85%;
  border-top: 1px solid #111;
  font-family: "Rogan-light";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 12px;
  color: #888;
  padding: 0 7.5%;
}

.footer_wrapper .sunfooter .king_logo_wrapper img {
  width: 50px;
}

.footer_wrapper .sunfooter .social_footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.footer_wrapper .sunfooter .social_footer .nav_item_social {
  width: 40px;
  height: 40px;
  border: 1px solid #ffffff;
  margin: 0 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}

.footer_wrapper .sunfooter .social_footer .nav_item_social i {
  color: #fff;
}

.footer_wrapper .sunfooter .social_footer .nav_item_social:hover {
  border: 1px solid #ff9900;
  color: #ff9900;
}

.footer_wrapper .sunfooter .social_footer .nav_item_social:hover i {
  color: #ff9900;
}

@media (min-width: 0px) and (max-width: 992px) {
  .footer_wrapper {
    width: 100%;
    min-height: 250px;
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .footer_wrapper .footer_container {
    width: 85%;
    min-height: 250px;
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 50px 0;
  }
  .footer_wrapper .footer_container .box_footer {
    min-height: 140px;
    height: auto;
    width: 90%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-transform: uppercase;
    border-bottom: 1px solid #222;
    padding: 20px 0 0 0;
  }
  .footer_wrapper .footer_container .box_footer:last-child {
    border-bottom: 1px solid rgba(34, 34, 34, 0);
  }
  .footer_wrapper .footer_container .box_footer span {
    color: #ff9900;
    font-size: 12px;
  }
  .footer_wrapper .footer_container .box_footer .info_text {
    color: #ff9900;
  }
  .footer_wrapper .footer_container .box_footer .text_print {
    font-family: "Rogan-light";
    margin-bottom: 10px;
  }
  .footer_wrapper .footer_container .box_footer .text_print span {
    color: #ff9900;
    font-family: "Rogan";
    font-size: 16px;
  }
  .footer_wrapper .footer_container .box_footer .title_category_footer {
    text-transform: uppercase;
    margin-bottom: 20px;
    letter-spacing: 5px;
    font-family: "Rogan-light";
    border-bottom: 1px solid #fff;
    padding: 5px 0;
  }
  .footer_wrapper .footer_container .box_footer img {
    width: 80px;
  }
  .footer_wrapper .footer_container .box_footer a {
    text-decoration: none;
  }
  .footer_wrapper .footer_container .box_footer a button {
    height: 50px;
    background-color: #ff9900;
    width: 250px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-transition: all ease-in-out 0.4s;
    transition: all ease-in-out 0.4s;
    border: none;
    text-transform: uppercase;
    font-weight: bold;
    font-family: "Rogan";
    cursor: pointer;
  }
  .footer_wrapper .footer_container .box_footer a button:hover {
    background-color: #c97800;
  }
  .footer_wrapper .sunfooter {
    height: 50px;
    font-family: "Rogan-light";
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    font-size: 10px;
    color: #888;
  }
  .footer_wrapper .sunfooter .king_logo_wrapper img {
    width: 50px;
  }
  .footer_wrapper .sunfooter .social_footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .footer_wrapper .sunfooter .social_footer .nav_item_social {
    width: 25px;
    height: 25px;
    border: 1px solid #ffffff;
    margin: 0 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    cursor: pointer;
    display: none;
  }
  .footer_wrapper .sunfooter .social_footer .nav_item_social i {
    color: #fff;
  }
  .footer_wrapper .sunfooter .social_footer .nav_item_social:hover {
    border: 1px solid #ff9900;
    color: #ff9900;
  }
  .footer_wrapper .sunfooter .social_footer .nav_item_social:hover i {
    color: #ff9900;
  }
}
