.resume_wrapper {
  min-height: 60vh;
  height: auto;
  width: 100%;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;

  .resume_container {
    height: inherit;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    .left {
      width: 50%;
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      .text_container {
        width: 60%;
        .category {
          text-transform: uppercase;
          margin-bottom: 20px;
          letter-spacing: 5px;
          font-family: "Rogan-light";
          border-bottom: 1px solid #fff;
          padding: 5px 0;
          width: 100px;
        }
        .text {
          font-family: "Rogan-light";
          font-size: 20px;
        }
      }
    }
    .right {
      width: 50%;
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
          width:70%;
          height: 600px;
          object-fit: cover;
          object-position: top;
      }
    }
  }
}


@media (min-width: 0px) and (max-width: 992px) {

  .resume_wrapper {
    min-height: 60vh;
    height: auto;
    width: 100%;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    .resume_container {
      height: inherit;
      width: 95%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column-reverse;
      .left {
        width: 90%;
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        .text_container {
          width: 90%;
          margin-top: 20px;
          .category {
            text-transform: uppercase;
            margin-bottom: 20px;
            letter-spacing: 5px;
            font-family: "Rogan-light";
            border-bottom: 1px solid #fff;
            padding: 5px 0;
            width: 100px;
          }
          .text {
            font-family: "Rogan-light";
          }
        }
      }
      .right {
        width: 90%;
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width:95%;
            height: 500px;
            object-fit: cover;
            object-position: top;
        }
      }
    }
  }
}