.agenda_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  align-items: center;
  background-color: #000;
  min-height: 100vh;
  height: auto;
  width: 100%;
  z-index: 9999;
  position: absolute;
}

.agenda_wrapper .agenda_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  min-height: 50vh;
  height: auto;
  width: 1366px;
  padding: 30px;
}

.agenda_wrapper .agenda_container .title {
  font-size: 3rem;
  height: 80px;
  border-bottom: 1px solid #fff;
  margin-bottom: 50px;
  text-transform: uppercase;
}

.agenda_wrapper .agenda_container .container_datas {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  min-height: 50vh;
  height: auto;
  width: 100%;
}

.agenda_wrapper .agenda_container .container_datas .data_box {
  min-height: 100px;
  height: auto;
  padding: 10px;
  width: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.agenda_wrapper .agenda_container .container_datas .data_box div {
  margin-bottom: 5px;
}

.agenda_wrapper .agenda_container .container_datas .data_box .day {
  font-size: 2.6rem;
}

.agenda_wrapper .agenda_container .container_datas .data_box .day span {
  color: #ff9900;
  font-family: "Rogan-light";
}

.agenda_wrapper .agenda_container .container_datas .data_box .day span::after {
  content: "hs";
}

.agenda_wrapper .agenda_container .container_datas .data_box .nome_evento {
  font-size: 1.5rem;
  text-transform: uppercase;
}

.agenda_wrapper .agenda_container .container_datas .data_box .endereco {
  font-family: "Rogan-light";
}

.agenda_wrapper .agenda_container .container_datas .data_box .mapa {
  color: #ff9900;
  text-transform: uppercase;
}

.agenda_wrapper .agenda_container .container_datas .data_box .paricular {
  text-transform: uppercase;
  font-size: 13px;
}

@media (min-width: 0px) and (max-width: 992px) {
  .agenda_wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    align-items: center;
    background-color: #000;
    min-height: 100vh;
    height: auto;
    width: 100%;
    z-index: 9999;
    position: absolute;
  }
  .agenda_wrapper .agenda_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    min-height: 50vh;
    height: auto;
    width: 100%;
    padding: 50px 0px;
  }
  .agenda_wrapper .agenda_container .title {
    font-size: 3rem;
    height: 80px;
    border-bottom: 1px solid #fff;
    margin-bottom: 50px;
    text-transform: uppercase;
    padding-left: 30px;
  }
  .agenda_wrapper .agenda_container .container_datas {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    min-height: 50vh;
    height: auto;
    width: 100%;
  }
  .agenda_wrapper .agenda_container .container_datas .data_box {
    min-height: 100px;
    height: auto;
    padding: 30px;
    width: 300px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    border-bottom: 1px solid #333333;
  }
  .agenda_wrapper .agenda_container .container_datas .data_box div {
    margin-bottom: 5px;
  }
  .agenda_wrapper .agenda_container .container_datas .data_box .day {
    font-size: 3rem;
  }
  .agenda_wrapper .agenda_container .container_datas .data_box .nome_evento {
    font-size: 1.5rem;
    text-transform: uppercase;
  }
  .agenda_wrapper .agenda_container .container_datas .data_box .endereco {
    font-family: "Rogan-light";
  }
  .agenda_wrapper .agenda_container .container_datas .data_box .mapa {
    color: #ff9900;
    text-transform: uppercase;
  }
  .agenda_wrapper .agenda_container .container_datas .data_box .paricular {
    text-transform: uppercase;
    font-size: 13px;
  }
}
