.menu_full{
  height: 100vh;
  width: 100%;
  background: #000;
  position: fixed;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  right: 0;
  display: none;
  .nav_wrapper{
    width: 200px;
    a{
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
      .nav_item{
        font-size: 2.5rem;
        transition: all ease .3s;
        &:hover {
          border-left: 8px solid #fff;
          color: #ff9900;
        }
      }
    }
  }
  .social_full{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    width: 200px;
    .category {
      text-transform: uppercase;
      margin-bottom: 20px;
      letter-spacing: 5px;
      font-family: "Rogan-light";
      border-bottom: 1px solid #fff;
      padding: 5px 0;
      width: 205px;
    }
    .social_full_center_icons{
      display: flex;
      align-items: center;
      justify-content: center;
      .nav_item_social{
        width: 40px;
        height: 40px;
        border: 1px solid #ffffff;
        margin: 0 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        i{
          color: #fff;
        }
        &:hover {
          border: 1px solid #ff9900;
          color: #ff9900;
          i{
            color: #ff9900;
          }
        }
      }
    }

  }
  .close{
    color: #fff;
    font-size: 30px;
    position: absolute;
    right: 10%;
    top: 30px;
    font-family: "Rogan-light";
    cursor: pointer;
  }
}
.menu_wrapper {
  height: 100px;
  width: 90%;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 900;
  padding: 0 5%;
  transition: all ease 0.3s;
  .nav_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nav_item {
      text-transform: uppercase;
      font-size: 12px;
      padding: 0 20px;
      transition: all ease-in-out 0.6s;
      cursor: pointer;

      &:hover {
        border-left: 8px solid #fff;
        color: #ff9900;
      }
    }
    a {
      text-decoration: none;
      .nav_item {
        color: #fff;
      }
    }
    .nav_item_social {
      text-transform: uppercase;
      font-size: 12px;
      padding: 0 5px;
      transition: all ease-in-out 0.6s;
      cursor: pointer;
      a{
        color: #fff;
        &:first-child{
          padding-left: 20px;
        }
        i{
          width: 35px;
          height: 35px;
          border: 1px solid #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all ease-in-out 0.3s;
          
          &:hover {
            border: 1px solid #ff9900;
            color: #ff9900;
            i{
              color: #ff9900;
            }
          }
        }
      }
      

    }
    .divisa {
      height: 20px;
      width: 1px;
      background: rgb(122, 122, 122);
      margin-right: 15px;
    }
  }
  .logo_wrapper {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // font-size: 30px;
    transition: all ease-in-out 0.3s;
    img {
      width: 50px;
      transition: all ease-in-out 0.3s;
    }
  }

  .drag_container{
    height: 20px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    display: none;
    div{
      height: 2px;
      width: 50%;
      background: #fff;
    }
  }
}
.active {
  background: #000;
  height: 70px;
  .logo_wrapper {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all ease-in-out 0.3s;
    img {
      width: 40px;
      transition: all ease-in-out 0.3s;
    }
  }
}



@media (min-width: 0px) and (max-width: 992px) {
  .menu_wrapper {
    height: 100px;
    width: 90%;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 900;
    padding: 0 5%;
    transition: all ease 0.3s;
    .nav_wrapper {

      display: none;

    }
    .logo_wrapper {
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // font-size: 30px;
      transition: all ease-in-out 0.3s;
      img {
        width: 50px;
        transition: all ease-in-out 0.3s;
      }
    }

    .drag_container{
      height: 20px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      div{
        height: 2px;
        width: 50%;
        background: #fff;
      }
    }
  }
  .active {
    background: #000;
    height: 70px;
    .logo_wrapper {
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: all ease-in-out 0.3s;
      img {
        width: 40px;
        transition: all ease-in-out 0.3s;
      }
    }
  }
  
}