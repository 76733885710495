.banner_wrapper {
  height: 100vh;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  overflow: hidden;
}

.banner_wrapper .banner_container {
  height: 100%;
  width: 100%;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 100;
}

.banner_wrapper .banner_container .left {
  width: 40%;
  height: 100vh;
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 10%;
}

.banner_wrapper .banner_container .left .category {
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 5px;
  font-family: "Rogan-light";
  border-bottom: 1px solid #fff;
  padding: 5px 0;
}

.banner_wrapper .banner_container .left .title {
  font-size: 6rem;
  line-height: 5rem;
  width: 400px;
  text-transform: uppercase;
  font-family: 'Archivo Black', sans-serif;
  letter-spacing: -10px;
}

.banner_wrapper .banner_container .left .sub_text {
  font-family: "Rogan-light";
  margin: 30px 0;
  font-size: 20px;
  width: 550px;
  text-transform: uppercase;
}

.banner_wrapper .banner_container .left .sub_text span {
  color: #ff9900;
  font-family: "Rogan";
}

.banner_wrapper .banner_container .left a {
  text-decoration: none;
}

.banner_wrapper .banner_container .left a button {
  height: 50px;
  background-color: #ff9900;
  width: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
  border: none;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Rogan";
  cursor: pointer;
}

.banner_wrapper .banner_container .left a button:hover {
  background-color: #c97800;
}

.banner_wrapper .banner_container .left .button_video {
  height: 100px;
  width: 100px;
  border: 1px solid #fff;
  border-radius: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 30px;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
  display: none;
}

.banner_wrapper .banner_container .left .button_video i {
  font-size: 25px;
}

.banner_wrapper .banner_container .left .button_video:hover {
  height: 120px;
  width: 120px;
}

.banner_wrapper .banner_container .right {
  width: 40%;
  height: 100vh;
  text-align: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-right: 10%;
}

.banner_wrapper .banner_container .right .category {
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 5px;
  font-family: "Rogan-light";
  border-bottom: 1px solid #fff;
  padding: 5px 0;
}

.banner_wrapper .banner_container .right .title {
  font-size: 6rem;
  line-height: 5rem;
  width: 600px;
  text-transform: uppercase;
}

.banner_wrapper .banner_container .right .sub_text {
  font-family: "Rogan-light";
  margin: 30px 0;
  font-size: 20px;
  width: 550px;
  text-transform: uppercase;
}

.banner_wrapper .banner_container .right .sub_text span {
  color: #ff9900;
  font-family: "Rogan";
}

.banner_wrapper .banner_container .right button {
  height: 50px;
  background-color: #ff9900;
  width: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
  border: none;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Rogan";
}

.banner_wrapper .banner_container .right .button_video {
  height: 100px;
  width: 100px;
  border: 1px solid #fff;
  border-radius: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 30px;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
  display: none;
}

.banner_wrapper .banner_container .right .button_video i {
  font-size: 25px;
}

.banner_wrapper .banner_container .right .button_video:hover {
  height: 120px;
  width: 120px;
}

.banner_wrapper .overlay {
  height: 117%;
  width: 100%;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, rgba(0, 0, 0, 0)), to(#000000)), url(../../Assets/img/malha2.png);
  background-image: linear-gradient(rgba(0, 0, 0, 0) 50%, #000000), url(../../Assets/img/malha2.png);
  position: absolute;
  z-index: 1;
}

.banner_wrapper #background-video {
  width: 100%;
  position: absolute;
  z-index: 0;
}

@media (min-width: 0px) and (max-width: 992px) {
  .banner_wrapper .banner_container .left {
    width: 80%;
    height: 100vh;
    padding-left: 0%;
  }
  .banner_wrapper .banner_container .left .title {
    font-size: 2.8rem;
    line-height: 2.4rem;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: -4px;
  }
  .banner_wrapper .banner_container .left .sub_text {
    width: 100%;
  }
  .banner_wrapper .banner_container .right {
    display: none;
  }
}
