.links_wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: #000;
  z-index: 9999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: hidden;
}

.links_wrapper .link_container {
  width: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}

.links_wrapper .link_container img {
  width: 70px;
  margin-bottom: 30px;
}

.links_wrapper .link_container a {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-decoration: none;
}

.links_wrapper .link_container a button {
  width: 90%;
  height: 60px;
  background: #ff9900;
  border: none;
  margin: 10px 0;
  font-weight: bold;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Rogan_extra";
  font-size: 19px;
  -webkit-transition: all ease-in-out .2s;
  transition: all ease-in-out .2s;
  cursor: pointer;
}

.links_wrapper .link_container a button:hover {
  background: #333;
  color: #ff9900;
  border: 1px solid #646464;
}

@media (min-width: 0px) and (max-width: 992px) {
  .links_wrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    background: #000;
    z-index: 9999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-top: 30px;
  }
  .links_wrapper .link_container {
    width: 600px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .links_wrapper .link_container img {
    width: 70px;
    margin-bottom: 30px;
  }
  .links_wrapper .link_container a {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-decoration: none;
  }
  .links_wrapper .link_container a button {
    width: 90%;
    height: 60px;
    background: #ff9900;
    border: none;
    margin: 10px 0;
    font-weight: bold;
    text-transform: uppercase;
    font-weight: bold;
    font-family: "Rogan_extra";
    font-size: 19px;
    -webkit-transition: all ease-in-out .2s;
    transition: all ease-in-out .2s;
    cursor: pointer;
  }
  .links_wrapper .link_container a button:hover {
    background: #333;
    color: #ff9900;
    border: 1px solid #646464;
  }
}
