.banner_wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  .banner_container {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    .left {
      width: 40%;
      height: 100vh;
      text-align: left;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      padding-left: 10%;

      .category {
        text-transform: uppercase;
        margin-bottom: 20px;
        letter-spacing: 5px;
        font-family: "Rogan-light";
        border-bottom: 1px solid #fff;
        padding: 5px 0;
      }
      .title {
        font-size: 6rem;
        line-height: 5rem;
        width: 400px;
        text-transform: uppercase;
        font-family: 'Archivo Black', sans-serif;
        letter-spacing: -10px;
      }

      .sub_text {
        font-family: "Rogan-light";
        margin: 30px 0;
        font-size: 20px;
        width: 550px;
        text-transform: uppercase;
        span {
          color: #ff9900;
          font-family: "Rogan";
        }
      }

      a {
        text-decoration: none;
        button {
          height: 50px;
          background-color: #ff9900;
          width: 300px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all ease-in-out 0.4s;
          border: none;
          text-transform: uppercase;
          font-weight: bold;
          font-family: "Rogan";
          cursor: pointer;
          &:hover {
            background-color: #c97800;
          }
        }
      }

      .button_video {
        height: 100px;
        width: 100px;
        border: 1px solid #fff;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        transition: all ease-in-out 0.4s;
        display: none;

        i {
          font-size: 25px;
        }
        &:hover {
          height: 120px;
          width: 120px;
        }
      }
    }

    .right {
      width: 40%;
      height: 100vh;
      text-align: right;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      flex-direction: column;
      padding-right: 10%;

      .category {
        text-transform: uppercase;
        margin-bottom: 20px;
        letter-spacing: 5px;
        font-family: "Rogan-light";
        border-bottom: 1px solid #fff;
        padding: 5px 0;
      }
      .title {
        font-size: 6rem;
        line-height: 5rem;
        width: 600px;
        text-transform: uppercase;
      }

      .sub_text {
        font-family: "Rogan-light";
        margin: 30px 0;
        font-size: 20px;
        width: 550px;
        text-transform: uppercase;
        span {
          color: #ff9900;
          font-family: "Rogan";
        }
      }
      button {
        height: 50px;
        background-color: #ff9900;
        width: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all ease-in-out 0.4s;
        border: none;
        text-transform: uppercase;
        font-weight: bold;
        font-family: "Rogan";
      }
      .button_video {
        height: 100px;
        width: 100px;
        border: 1px solid #fff;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        transition: all ease-in-out 0.4s;
        display: none;

        i {
          font-size: 25px;
        }
        &:hover {
          height: 120px;
          width: 120px;
        }
      }
    }
  }
  .overlay {
    height: 117%;
    width: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0) 50%, #000000),
      url(../../Assets/img/malha2.png);
    position: absolute;
    z-index: 1;
  }
  #background-video {
    width: 100%;
    position: absolute;
    z-index: 0;
  }
}

@media (min-width: 0px) and (max-width: 992px) {
  .banner_wrapper {
    .banner_container {
      .left {
        width: 80%;
        height: 100vh;
        padding-left: 0%;

        .title {
          font-size: 2.8rem;
          line-height: 2.4rem;
          width: 100%;
          text-transform: uppercase;
          letter-spacing: -4px;
        }

        .sub_text {
          width: 100%;
        }
      }

      .right {
        display: none;
      }
    }
  }
}
