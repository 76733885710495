.video_wrapper {
  min-height: 100vh;
  height: auto;
  width: 100%;
  background: #000;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-bottom: 1px solid #111;
  .video_container {
    min-height: 100vh;
    height: auto;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .category {
      text-transform: uppercase;
      margin-bottom: 20px;
      letter-spacing: 5px;
      font-family: "Rogan-light";
      border-bottom: 1px solid #fff;
      padding: 5px 0;
      margin: 50px 0;
    }
    iframe {
      width: 1080px;
      height: 600px;
    }
  }

  .category_streamings {
    text-transform: uppercase;
    margin-bottom: 20px;
    letter-spacing: 5px;
    font-family: "Rogan-light";
    border-bottom: 1px solid #fff;
    padding: 5px 0;
    margin: 50px 0 0px 0;
  }

  .social_container {
    height: 150px;
    margin:0 0 50px 0;
    width: 105%;
    display: flex;
    align-items: center;
    justify-content: center;
    // background: rgb(8, 8, 8);

    .social_middle {
      width: 1080px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 130px;
      }
    }
    img {
      width: 130px;
      transition: all ease-in-out 0.4s;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 992px) {
  .video_wrapper {
    min-height: 100vh;
    height: auto;
    width: 100%;
    background: #000;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .video_container {
      min-height: 100vh;
      height: auto;
      width: 95%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .category {
        text-transform: uppercase;
        margin-bottom: 20px;
        letter-spacing: 5px;
        font-family: "Rogan-light";
        border-bottom: 1px solid #fff;
        padding: 5px 0;
        margin: 50px 0;
      }
      iframe {
        width: 90%;
        height: 200px;
      }
    }

    .social_container {
      min-height: 150px;
      height: auto;
      margin: 50px 0;
      width: 105%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgb(8, 8, 8);

      .social_middle {
        width: 80%;
        min-height: 150px;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        border: 1px solid rgba(255, 255, 255, 0.11);
        img {
          width: 130px;
          margin: 20px 0;
        }
      }
      img {
        width: 130px;
        transition: all ease-in-out 0.4s;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}
