.links_wrapper{
    width: 100%;
    height: 100vh;
    position: fixed;
    background: #000;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .link_container{
        width: 600px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;;
        overflow: hidden;

        img{
            width: 70px;
            margin-bottom: 30px;
        }
        a{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            button{
                width: 90%;
                height: 60px;
                background: #ff9900;
                border: none;
                margin: 10px 0;
                font-weight: bold;
                text-transform: uppercase;
                font-weight: bold;
                font-family: "Rogan_extra";
                font-size: 19px;
                transition: all ease-in-out .2s;
                cursor: pointer;
                &:hover{
                    background: #333;
                    color: #ff9900;
                    border: 1px solid rgb(100, 100, 100);
                }
            }
        }
    }
}

@media (min-width: 0px) and (max-width: 992px) {
    .links_wrapper{
        width: 100%;
        height: 100vh;
        position: fixed;
        background: #000;
        z-index: 9999;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding-top: 30px;
        .link_container{
            width: 600px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;

            img{
                width: 70px;
                margin-bottom: 30px;
            }
            a{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                button{
                    width: 90%;
                    height: 60px;
                    background: #ff9900;
                    border: none;
                    margin: 10px 0;
                    font-weight: bold;
                    text-transform: uppercase;
                    font-weight: bold;
                    font-family: "Rogan_extra";
                    font-size: 19px;
                    transition: all ease-in-out .2s;
                    cursor: pointer;
                    &:hover{
                        background: #333;
                        color: #ff9900;
                        border: 1px solid rgb(100, 100, 100);
                    }
                }
            }
        }
    }
    
}
