@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');
.App {
  height: 100vh;
  width: 100%;
  background: #060B0A;
  color: #fff;
  font-family: "Rogan";
}

@font-face {
  font-family: "ts";
  src: url(./Assets/fonts/Akira\ Expanded\ Demo.otf);
}

@font-face {
  font-family: "Rogan";
  src: url(./Assets/fonts/Rogan-Bold.ttf);
}

@font-face {
  font-family: "Rogan-light";
  src: url(./Assets/fonts/Rogan-Light.ttf);
}

@font-face {
  font-family: "Rogan_extra";
  src: url(./Assets/fonts/Rogan-ExtraBold.ttf);
}


