.inicio_wrapper {
  height: 100vh;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: url(../../Assets/img/back.png) no-repeat;
  background-position: center;
  background-size: cover;
}

.inicio_wrapper .inicio_container {
  width: 1366px;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.inicio_wrapper .inicio_container .left {
  width: 50%;
  height: 70vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-item-align: center;
      align-self: center;
}

.inicio_wrapper .inicio_container .left button {
  height: 50px;
  width: 300px;
  border: none;
  text-transform: uppercase;
  font-weight: bold;
  background: #fff;
  font-family: "Rogan_extra";
  font-size: 20px;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}

.inicio_wrapper .inicio_container .left button:hover {
  background: #e1ff19;
}

.inicio_wrapper .inicio_container .left .text_middle {
  display: none;
}

.inicio_wrapper .inicio_container .left .text_middle .text {
  font-size: 5.5rem;
  line-height: 4.5rem;
  font-family: "ts";
}

.inicio_wrapper .inicio_container .left .text_middle .text .isso span {
  position: relative;
  left: -50px;
  top: -20px;
}

.inicio_wrapper .inicio_container .left .text_middle .sub {
  width: 80%;
  font-family: "Rogan-light";
  margin-top: 30px;
  display: none;
}

.inicio_wrapper .inicio_container .left img {
  width: 150px;
  display: none;
}

.inicio_wrapper .inicio_container .image {
  width: 50%;
  height: 90vh;
}

@media (min-width: 0px) and (max-width: 992px) {
  .inicio_wrapper .inicio_container {
    width: 100% !important;
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }
  .inicio_wrapper .inicio_container .left {
    width: 100%;
    height: calc(100vh - 160px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-item-align: center;
        align-self: center;
  }
  .inicio_wrapper .inicio_container .left button {
    height: 50px;
    width: 300px;
    border: none;
    text-transform: uppercase;
    font-weight: bold;
    background: #fff;
    font-family: "Rogan_extra";
    font-size: 20px;
    -webkit-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
  }
  .inicio_wrapper .inicio_container .left button:hover {
    background: #e1ff19;
  }
  .inicio_wrapper .inicio_container .image {
    width: 50%;
    height: 90vh;
    display: none;
  }
}
