.player_wrapper {
  height: 70px;
  width: 90%;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 5%;
  color: black;
  z-index: 100;
  border-top: 1px solid #d8d8d8;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  background: white;
}

.player_wrapper .description_music_wrapper {
  width: 400px;
  height: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.player_wrapper .description_music_wrapper .cover_wrapper div {
  height: 35px;
  width: 35px;
  background: #333;
  margin-right: 10px;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.player_wrapper .description_music_wrapper .cover_wrapper div img {
  height: 35px;
  width: 35px;
  border-radius: 50px;
}

.player_wrapper .description_music_wrapper .description div:nth-child(1) {
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
}

.player_wrapper .description_music_wrapper .description div:nth-child(2) {
  font-size: 11px;
  color: #777;
}

.player_wrapper .controls_wrapper {
  height: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.player_wrapper .controls_wrapper .more {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 11px;
  border: none;
  height: 25px;
  width: 25px;
  border-radius: 50px;
  border: 1px solid #b1b1b19c;
  cursor: pointer;
}

.player_wrapper .controls_wrapper .time {
  font-size: 11px;
  margin: 0 10px;
  width: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.player_wrapper .controls_wrapper .buttonPlayer {
  height: 30px;
  width: 30px;
  border-radius: 50px;
  margin: 0 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}

.player_wrapper .controls_wrapper .play {
  height: 40px;
  width: 40px;
  border-radius: 40px;
  margin: 0 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  border: 1px solid #b1b1b19c;
}

.player_wrapper .controls_wrapper .play i {
  position: relative;
  font-size: 12px;
}

.player_wrapper .seekBar_wrapper {
  width: 60%;
  height: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.player_wrapper .seekBar_wrapper .seekbar {
  width: 80%;
  background: #333;
  height: 5px;
  margin: 0 20px;
}

.player_wrapper .platform_container {
  width: 400px;
  height: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding-right: 20px;
  margin-left: 20px;
  border-radius: 0 10px 10px 0;
}

.player_wrapper .platform_container .nav_item {
  margin: 20px;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.player_wrapper .platform_container .nav_item a {
  color: #000;
  text-decoration: none;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.player_wrapper .platform_container .nav_item a:hover {
  border-left: 8px solid #fff;
  color: #ff9900;
}

@media (min-width: 0px) and (max-width: 992px) {
  .player_wrapper .description_music_wrapper {
    display: none;
  }
  .player_wrapper .controls_wrapper {
    width: 100%;
  }
  .player_wrapper .seekBar_wrapper {
    display: none;
  }
  .player_wrapper .platform_container {
    display: none;
  }
}
